



















































import Vue from 'vue'
import Component from 'vue-class-component'
import { Action } from 'vuex-class'
import util from '@/utils/util'
import globalMixins from '@/mixins/global.ts'

@Component({
  mixins: [globalMixins]
})
export default class Login extends Vue<globalMixins> {
  @Action('verifyCode') verifyCode: Function
  @Action('resetPassword') resetPassword: Function

  Btn: string =  "获取验证码"
  TIME_COUNT: any = 60  
  isDisabled: boolean = false

  fixed = false
  fieldData = {
    phone: '',
    password: '',
    code: ''
  }

  rule = {
    phone: [
      {
        required: true,
        message: '手机号是必须的',
        trigger: 'blur'
      }
    ],
    code: [
      {
        required: true,
        message: '验证码必填',
        trigger: 'blur'
      }
    ],
    password: [
      {
        required: true,
        message: '密码是必须的！',
        trigger: 'blur'
      }
    ]
  }

  submit () {
    let fieldData = this.$refs.fieldData as HTMLFormElement
    fieldData.validate((valid: boolean) => {
      if (valid) {
        this.isDisabled = true
        let params = {
          phone: this.fieldData.phone,
          password: this.fieldData.password,
          verify_code: this.fieldData.code
        }
        this.resetPassword({ params }).then((res) => {
          if (res.success) {
            this.successMsg('修改密码成功！')
          } else {
            this.errorMsg('修改密码失败！')
          }
        })
      } else {
        this.isDisabled = false
        this.errorMsg('修改密码失败！')
      }
    })
  }

  sendMsg() {
    let params: Object = {}
    let phone = this.fieldData.phone

    if (util.isPhone(phone)) {
      params["phone"] = this.fieldData.phone
      this.verifyCode({ params })
      this.isDisabled = true
      let interval = window.setInterval(() => {
        this.Btn = '（' + this.TIME_COUNT + '秒）后重新发送'
        --this.TIME_COUNT
        this.Btn = this.TIME_COUNT
        if (this.TIME_COUNT < 0) {
          this.Btn = "重新发送"
          this.TIME_COUNT = 60
          this.isDisabled = false
          window.clearInterval(interval)
        }
      }, 1000)
    } else {
      this.message('请输入有效手机号！')
    }
  }

  message (msg: string) {
    this.errorMsg(msg)
  }
  
  login () {
    this.$router.push('/')
  }
}
